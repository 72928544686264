<template>
  <b-navbar toggleable="lg" class="bg-gray-200">
    <b-navbar-toggle
      v-if="account.Email"
      target="nav-collapse"
    ></b-navbar-toggle>

    <router-link :to="{ name: 'items', params: { lang: this.$i18n.locale } }">
      <img
        @click="open = false"
        alt="Logo Provinciale Uitleendienst Limburg"
        src="/logo.svg"
        class="logo"
      />
    </router-link>
    <router-link
      v-if="account.Email"
      :to="{ name: 'cart', params: { lang: this.$i18n.locale } }"
    >
      <b-button variant="light" class="ml-0 mr-3 mb-md-0">
        <shopping-cart />
        {{ cartCount > 0 ? cartCount : '' }}
      </b-button>
    </router-link>

    <b-collapse id="nav-collapse" v-model="open" is-nav>
      <b-navbar-nav>
        <b-nav-form class="mt-3">
          <router-link
            :to="{ name: 'cart', params: { lang: this.$i18n.locale } }"
          >
            <b-button variant="light" class="ml-0 mr-3 mb-md-0">
              <shopping-cart />
              {{ cartCount > 0 ? cartCount : '' }}
            </b-button>
          </router-link>
          <div class="language-picker">
            <language-change-button language="en" class="mr-1 ml-1" />
            <language-change-button language="nl" class="mr-1 ml-1" />
            <language-change-button language="fr" class="mr-1 ml-1" />
          </div>
        </b-nav-form>
        <hr />
        <b-nav-item
          v-for="category in categories"
          :key="category.CategoryID"
          :href="`/${$i18n.locale}/shop/${category.CategoryID}`"
          >{{ category.Description }}</b-nav-item
        >
        <hr />
        <b-nav-form>
          <router-link
            v-if="!account.Email"
            :to="{ name: 'login', params: { lang: this.$i18n.locale } }"
          >
            <b-button variant="grey" class="mr-3">{{
              $t('header.login')
            }}</b-button>
          </router-link>
          <router-link
            v-if="account.Email"
            :to="{ name: 'account', params: { lang: this.$i18n.locale } }"
          >
            <b-button variant="grey" class="mr-3 ml-0 pl-0 pr-3 mb-2 mb-md-0">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="user-circle"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 496 512"
                class="svg-inline--fa fa-user-circle fa-w-16 fa-5x"
              >
                <path
                  fill="currentColor"
                  d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                  class=""
                ></path></svg
              >&nbsp;{{ $t('header.myAccount') }}
            </b-button>
          </router-link>
        </b-nav-form>
        <!-- Right aligned nav items -->

        <b-navbar-nav>
          <b-nav-form> </b-nav-form>
          <b-nav-form>
            <b-button
              v-if="account.Email"
              @click="logout"
              class="mr-3 pl-0 ml-0 pr-3 mb-2 mb-md-0"
              variant="grey"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="sign-out-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="svg-inline--fa fa-sign-out-alt fa-w-16 fa-5x"
              >
                <path
                  fill="currentColor"
                  d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                  class=""
                ></path></svg
              >&nbsp;{{ $t('header.logout') }}
            </b-button>
          </b-nav-form>
        </b-navbar-nav>

        <b-nav-form
          @submit.prevent="search"
          class="search"
          style="min-width:280px"
        >
          <b-input-group class>
            <b-form-input
              v-model="searchTerm"
              :state="searchValidation"
              :placeholder="$t('general.searchFor')"
              class="my-2"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" class="my-2 mr-3" type="submit">{{
                $t('general.search')
              }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import LanguageChangeButton from './LanguageChangeButton'
import ShoppingCart from '@/components/icons/IconShoppingCart'

export default {
  components: {
    LanguageChangeButton,
    ShoppingCart
  },
  props: {
    account: {
      required: true,
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data: function() {
    return {
      searchTerm: '',
      open: false
    }
  },
  computed: {
    user: function() {
      return this.$store.getters.user
    },
    cartCount() {
      return this.$store.getters.cartCount
    },
    categories: function() {
      return this.$store.getters['categories/getCategories'].filter(
        category => category.ParentCategoryID === null
      )
    },
    searchValidation: function() {
      if (
        this.searchTerm.length > 1 ||
        this.searchTerm.length == 0 ||
        this.searchTerm == ''
      ) {
        return null
      } else {
        return false
      }
    }
  },
  methods: {
    logout: async function() {
      await this.$store.commit('unsetCart')
      await this.$store.commit('unsetUser', {
        reason: 'loggedOut=true'
      })
      window.location.reload()
    },
    search: async function(event) {
      event.preventDefault()
      if (this.searchTerm.length > 1) {
        await this.$router.push({
          name: 'search',
          params: { searchTerm: this.searchTerm }
        })
        this.searchTerm = ''
      }
    }
  }
}
</script>

<style>
@media screen and (max-width: 500px) {
  .logo {
    max-width: 215px;
  }
}
.logo {
  height: 40px;

  position: absolute;
  left: 0;
  top: 10px;
  right: 0;
  margin-top: -3px;
  margin-left: auto;
  margin-right: auto;
}
</style>
