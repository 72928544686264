<template>
  <div class="flex justify-center pt-3">
    <div class="pl-3 pr-3"><router-link to="/">Home</router-link></div>
    <div class="pl-3 pr-3">
      <a
        target="_blank"
        class="hover:text-primary-light text-gray"
        href="https://www.limburg.be/disclaimer"
        rel="noreferrer"
        >Disclaimer</a
      >
    </div>
    <div class="pl-3 pr-3">
      <a
        target="_blank"
        class="hover:text-primary-light text-gray"
        href="https://www.limburg.be/Limburg/pul/Officieel_reglement.html"
        rel="noreferrer"
        >Reglement</a
      >
    </div>

    <div class="pl-3 pr-3">
      <a
        target="_blank"
        href="https://www.limburg.be/privacybeleid"
        rel="noreferrer"
        >Privacy</a
      >
    </div>
    <div class="pl-3 pr-3">
      <a target="_blank" href="https://www.limburg.be/contact" rel="noreferrer"
        >Contact</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterTop'
}
</script>
